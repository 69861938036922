import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Ambulance from "../../../assets/Ambulance.webp";
import AnnualHealth from "../../../assets/AnnualHealth.webp";
import Diagnostics from "../../../assets/Diagnostics.webp";
import Doctor from "../../../assets/Doctor.webp";
import Epharmacy from "../../../assets/Epharmacy.webp";
import Nurse from "../../../assets/Nurse.webp";
import PreEmployment from "../../../assets/PreEmployment.webp";
import Vaccinations from "../../../assets/Vaccinations.webp";
import WomenCare from "../../../assets/WomenCare.webp";
import DiseaseM from "../../../assets/DiseaseM.webp";
import MentalHealth from "../../../assets/MentalHealth.webp";

import HealthCoach from "../../../assets/HealthCoach.webp";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "3px",
    maxWidth: 200,
  },
  heading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.7rem !important",
    // marginTop: '3% !important',
    color: "#8600C9 !important",
    paddingTop: "2em !important",
  },
  subheading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.1em !important",
    color: "black !important",
  },
  subheading1: {
    fontFamily: "Raleway !important",
    textAlign: "center !important",
    fontSize: "1em !important",

    color: "black !important",
    lineHeight: "1.1em!important",
  },
  background: {
    paddingBottom: "3em !important",
    backgroundColor: "#FAE8FF !important",
  },
  adjust: {
    borderRadius: "5em !important",
    marginLeft: "20em !important",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardadjustment: {
    marginLeft: "30rem !important",
  },
  appBar: {
    backgroundColor: "#dcedc8",
  },
  hero: {
    height: "500em",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#dcedc8",
    fontSize: "4em",
  },
  blogsContainer: {
    paddingTop: theme.spacing(6),
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3),
  },
  card: {
    maxWidth: "100%",
    marginTop: "-2.5em !important",
    height: "100%",
  },
  media: {
    height: 240,
  },
  cardActions: {
    display: "flex",
    margin: "0 10em",
    justifyContent: "space-between",
  },
  author: {
    display: "flex",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    marginLeft: "4.1em !important",
    marginTop: "-2.5em !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "6em !important",
    },
  },
  col: {
    margin: theme.spacing("2em", "0em", "0em", "0em"),
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  allignment: {
    fontSize: "1em !important",
    marginBottom: "0px !important",
    marginTop: "0px !important",
    textAlign: "center !important",
    fontSize: "0.9em !important",
  },
  light: {
    textAlign: "center !important",
    color: "black !important",
    lineHeight: "1.5rem !important",
    fontSize: "1em !important",
    fontFamily: "Raleway !important",
  },
}));

const Offerings = [
  {
    id: 1,
    image: Doctor,
    cardHeading: "Doctor Consultations",
    cardContent:
      "Doctor consultations (virtual & ons-site) assure the employees that they are being looked after and help improve employee efficiency and loyalty.",
  },
  {
    id: 2,
    image: Diagnostics,
    cardHeading: "Diagnostics",
    cardContent:
      "Book diagnostic tests nearest your home and have expert technicians come in to collect lab samples. Unparalleled convenience provided by Assurance by NanoHealth!",
  },
  {
    id: 3,
    image: Ambulance,
    cardHeading: "Ambulance Services",
    cardContent:
      "Assurance ambulance service focuses on reaching people during an emergency, providing life-saving first aid on the spot, and assisting patients in healthcare facilities.",
  },
  {
    id: 4,
    image: AnnualHealth,
    cardHeading: "Annual health checkup",
    cardContent:
      "Employees' physical yearly health checkup plays a vital role in managing health care and awareness to prevent the risk of development/progression of chronic diseases.",
  },
  {
    id: 5,
    image: Vaccinations,
    cardHeading: "Vaccinations",
    cardContent:
      "Preventive Healthcare strategies provide employees with mental & Physical Security, Improves Morale & Team Spirit,enhance productivity, and reduce absenteeism.",
  },
  {
    id: 6,
    image: Epharmacy,
    cardHeading: "E-pharmacy",
    cardContent:
      "Employees can upload prescriptions, buy medicines & refill prescriptions without stepping out of the comfort of their homes.",
  },
  {
    id: 7,
    image: DiseaseM,
    cardHeading: "Disease Management ",
    cardContent:
      "Assurance Disease management programs offer an organized & proactive approach for chronic diseases like diabetes and hypertension through doctor consults, health coaches, 24/7 chat support, etc. ",
  },
  {
    id: 8,
    image: Nurse,
    cardHeading: "Nurse-on call 24/7",
    cardContent:
      "Packages on-boarding introductions, queries, symptoms, and vital monitoring by a nurse 24/7. Unparalleled convenience provided by Assurance by NanoHealth!",
  },
  {
    id: 9,
    image: WomenCare,
    cardHeading: "Women's health care ",
    cardContent:
      "Hectic schedules, taking care of home, kids & us-Women need all the care and attention. Opt for our women's health care packages for well-rounded healthcare services.",
  },
  {
    id: 10,
    image: PreEmployment,
    cardHeading: "Pre-employment Tests",
    cardContent:
      "Pre-employment testing can help HR professionals minimize hiring time and select the most qualified individual who best fits the organization.",
  },
  {
    id: 11,
    image: HealthCoach,
    cardHeading: "Health coaches",
    cardContent:
      "Using one on one consulting approach our health coaches help you to achieve health and fitness goals, adhere to treatments and motivate you to keep up the good work.",
  },
  {
    id: 12,
    image: MentalHealth,
    cardHeading: "Mental health  ",
    cardContent:
      "We encourage you to talk and ask you not to ignore the signs. Be it post covid stress or any other issue that is bothering your peace of mind, our healthcare experts have a friendly ear.",
  },
];

const Ourofferings = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.background}>
        <Typography
          className={classes.heading}
          variant="h2"
          gutterBottom
          component="div"
        >
          Our Offerings
        </Typography>
        <Typography
          className={classes.subheading}
          variant="h3"
          gutterBottom
          component="div"
        >
          Corporate healthcare solutions
        </Typography>
        <p className={classes.subheading1}>
          Are you ready to inspire healthy employees, offer well-rounded
          healthcare options and realize he althy savings?
        </p>
        <p className={classes.subheading1}>
          Improved Employee Health - Increased Productivity{" "}
        </p>
        <p className={classes.subheading1}>Reduced Heath care costs</p>
        <Container maxWidth="lg" className={classes.blogsContainer}>
          <Grid container spacing={8}>
            {Offerings.map((Offering) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={Offering.id}>
                  <Avatar
                    className={classes.avatar}
                    alt="Remy Sharp"
                    src={Offering.image}
                    sx={{ width: 76, height: 76 }}
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.col}>
                      <Typography gutterBottom variant="h6" component="h2">
                        <h5 className={classes.allignment}>
                          {Offering.cardHeading}
                        </h5>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <p className={classes.light}>{Offering.cardContent}</p>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default Ourofferings;
