import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Analyticsicon from "../../../assets/AnalyticsIcon.png";
import HealthCoaches from "../../../assets/HealthCoaches.png";
import Stopsolution from "../../../assets/Stopsolution.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    marginTop: "-1em !important",
  },
  heading: {
    fontFamily: "Raleway !important",
    color: "#8600c9 !important",
    marginTop: "-0.5em !important",
    backgroundColor: "#FAE8FF !important",
    fontWeight: "600 !important",
    fontSize: "1.7em !important",
  },
  allignment: {
    fontFamily: "Raleway !important",
    fontSize: "1.2rem !important",
    marginLeft: "8em !important",
    marginTop: "-4.1em !important",
    width: "80% !important",
    fontWeight: "500 !important",
    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      width: "50 %  !important",
      marginLeft: "1.7em !important",
      marginTop: "-0em !important",
    },
  },
  allignment1: {
    marginLeft: "9.5em !important",
    lineHeight: "2rem !important",
    width: "80% !important",
    fontFamily: "Raleway !important",
    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      width: "100% !important",
      marginLeft: "0em !important",
      padding: theme.spacing("0.5em"),
    },
  },
  avatar: {
    marginTop: "3em !important",
    marginLeft: "3.2em !important",
    [theme.breakpoints.down("md")]: {
      marginRight: "0em !important",
      marginTop: "1em !important",
      marginLeft: "5.2em!important",
      marginBottom: "1em !important",
    },
  },
}));

const WhyUsDetails = [
  {
    id: 1,
    image: Analyticsicon,
    cardHeading: "Analytics",
    cardContent:
      "The digital reports and analytics will not only show you the current health scenario of an employee or the company but also suggest various ways in which the overall health can be improved.",
  },
  {
    id: 2,
    image: HealthCoaches,
    cardHeading: "Health Coaches",
    cardContent:
      "We exclusively provide dedicated health coaches for your employees that not only accompany them in the journey but also ensure continuous conversations around health and adherence towards treatment.",
    cardContent1:
      "This is a big shift in providing healthcare which is proactive and not transactional  ",
  },
  {
    id: 3,
    image: Stopsolution,
    cardHeading: "Comprehensive one stop solution",
    cardContent:
      "Standing true to being one of the comprehensive platforms for health, our single most aim is to ensure that the workforce is active and healthy at all times.",
  },
  {
    id: 4,
    image: HealthCoaches,
    cardHeading: "Health Analytics",
    cardContent:
      "Our health analytics reflect the current health scenario of an employee or the company and suggest recommendations to improve health.",
    cardContent1:
      "Other services also include happiness coaches, stress managers, women health, disease management, dieticians and more. ",
    cardContent2:
      "Proven record of improving the health quotient from 11% to 75% usage by the employees  ",
  },
];

const Whyus = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={1}>
          {WhyUsDetails.map((Details) => {
            return (
              <Grid item xs={12} sm={6} md={12} key={Details.id}>
                <Avatar
                  className={classes.avatar}
                  alt="Remy Sharp"
                  src={Details.image}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography
                  variant="h4"
                  component="div"
                  className={classes.allignment}
                >
                  {Details.cardHeading}
                </Typography>
                <Typography
                  variant="h7"
                  component="div"
                  className={classes.allignment1}
                >
                  {Details.cardContent}
                </Typography>
                <Typography
                  variant="h7"
                  component="div"
                  className={classes.allignment1}
                >
                  {Details.cardContent1}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  className={classes.allignment1}
                >
                  {Details.cardContent2}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
export default Whyus;
