import React, { useState, useEffect } from "react";
import Header from "../components/ui/Header";
import theme from "./ui/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/ui/Footer";
import LandingPage from "./ui/LandingPage";
import { StyledEngineProvider } from "@mui/styled-engine";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./ui/About/About";
import Ourofferings from "./ui/ourofferings/OfferingsWithBanner";
import Blogs from "./ui/Blogs/Blogs";
import ReactGa from "react-ga";
import BlogID from "../components/ui/Blogs/BlogID";
import BlogList from "../components/ui/Blogs/BlogList";

const App = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    ReactGa.initialize("UA-216358984-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider>
        <Header value={value} setValue={setValue} />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="ourofferings" element={<Ourofferings />} />
          <Route path="aboutus" element={<About />} />
          <Route path="/blogs" element={<Blogs />}>
            <Route index element={<BlogList />} />
            <Route path=":id" element={<BlogID />} />
          </Route>
          <Route path="scheduledemo" element={<LandingPage />} />
        </Routes>
        <Footer />
      </StyledEngineProvider>
    </ThemeProvider>
  );
};
export default App;
