import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import imgone from "../../assets/Banner1.webp";
import imgtwo from "../../assets/Banner2.webp";
import imgthree from "../../assets/Banner3.webp";
import Slick from "../slickSlider/Slick";
import Ourofferings from "./ourofferings/Ourofferings";
import AssuranceTalk from "./AssuranceTalk";
import { makeStyles } from "@mui/styles";
import Drive from "./Drive";
import SlickOne from "../slickSlider/SlickOne";
import Awards from "./Awards";
import Contact from "./Contact";
import Covidbanner from "./Covidbanner";

const useStyles = makeStyles((theme) => ({
  position: {
    position: "absolute !important",
    textAlign: "left !important",
    width: "45vw !important",
    color: "#white !important",
    fontSize: "2.3vw !important",
    fontWeight: "600 !important",
    color: "white !important",
    lineHeight: "3vw !important",

    margin: theme.spacing("15vw", "0vw", "2vw", "7vw"),
    fontFamily: "Raleway !important",
  },
  position1: {
    textAlign: "left !important",
    width: "45vw!important",
    position: "absolute !important",
    fontSize: "1.5vw !important",
    fontWeight: "550 !important",

    margin: theme.spacing("22vw", "0vw", "3vw", "7vw"),
    fontFamily: "Raleway !important",
    lineHeight: "2vw !important",
  },
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    id: 1,
    imgPath: imgone,
    text: "Nano Health is your team's one-stop solution for comprehensive wellness.",
    text1: "Think beyond sick care to transform your employees.",
  },
  {
    id: 2,
    imgPath: imgtwo,
    text: "Attain financial peace with reliable healthcare programs at affordable costs.",
    text1: "Our wellness programs guarantee the return of investments.",
  },
  {
    id: 3,
    imgPath: imgthree,
    text: "Timely consultations with specialists anywhere & anytime.",
    text1: "Constructive & coordinated healthcare solutions by experts.",
  },
];

const LandingPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
        <Paper
          square
          elevation={1}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
          }}
        >
          <Typography>{images[activeStep].label}</Typography>
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div>
              <h2 className={classes.position} key={index}>
                {" "}
                {step.text}{" "}
              </h2>
              <p className={classes.position1}> {step.text1}</p>

              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                    animation: "slide 20s infinite",
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Slick />
      {/* <Covidbanner /> */}
      <Ourofferings />
      <Drive />
      <AssuranceTalk />
      <Awards />
      <SlickOne />
      <Contact />
    </>
  );
};
export default LandingPage;
