import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginLeft: "3.8em !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    fontWeight: "600 !important",
    fontSize: "1.7em !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center ",
      marginLeft: "0em !important",
      marginBottom: "0em !important",
    },
  },
  heading1: {
    fontSize: "1em !important",
    fontFamily: "Raleway !important",
    lineHeight: "1.5rem !important",
    fontFamily: "Raleway !important",
    marginLeft: "6.5em !important",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      fontWeight: "500 !important",
      width: "auto",
      marginLeft: "0em !important",
      padding: theme.spacing("0.5em"),
      marginBottom: "0em !important",
    },
  },
}));
const Howdowedoit = () => {
  const classes = useStyles();

  return (
    <>
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        className={classes.heading}
      >
        How do we do it ?{" "}
      </Typography>
      <p className={classes.heading1}>
        We have a nationwide presence and host a pool of specialized doctors,
        hospitals and laboratories that enable prompt healthcare at scale.
      </p>
      <p className={classes.heading1}>
        We provide tech based healthcare, through which a user can access not
        just the availability of labs in their area but also track their health
        journey and access help from a wide variety of health subjects. for
        employees and their dependants
      </p>
    </>
  );
};

export default Howdowedoit;
