import React from "react";
import Screening from "../../../assets/Screeningcard.webp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  adjust: {
    position: "absolute !important",
    width: "18% !important",
    marginLeft: "64rem !important",
    marginTop: "53em !important",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
}));

const ScreeningCard = () => {
  const classes = useStyles();
  return (
    <div>
      <a
        href="https://www.nhassurance.com/screening-test"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Screening} alt="clients logo" className={classes.adjust} />
      </a>
    </div>
  );
};

export default ScreeningCard;
