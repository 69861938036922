import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Competitive from "../../../assets/Competitive.png";
import Geographical from "../../../assets/Geographical.png";
import Comprehensive from "../../../assets/Comprehensive.png";
import Health from "../../../assets/Health.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAE8FF !important",
    padding: theme.spacing(5),
    marginBottom: "3.1em !important",
    marginTop: "-1em !important",
  },
  heading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    color: "#8600c9 !important",
    marginBottom: "2em !important",
    marginTop: "-0.5em !important",
    backgroundColor: "#FAE8FF !important",
    marginLeft: "2.2em !important",
    fontSize: "1.7rem !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "0em !important",
    },
  },
  allignment: {
    fontFamily: "Raleway !important",
    fontSize: "1.2rem !important",
    marginLeft: "8em !important",
    marginTop: "-4.1em !important",
    width: "45% !important",
    fontWeight: "500 !important",
  },
  allignment1: {
    marginLeft: "9.5em !important",
    width: "50% !important",
    fontFamily: "Raleway !important",
  },
  avatar: {
    marginTop: "2em !important",
    marginLeft: "3em !important",
  },
}));

const PrespectiveDetails = [
  {
    id: 1,
    image: Competitive,
    cardHeading: " Competitive Pricing & Customized Health packages",
    cardContent:
      "You get lucrative healthcare not just for you but also for your family nationwide",
  },
  {
    id: 2,
    image: Geographical,
    cardHeading: " Geographical coverage",
    cardContent:
      "Our services are availabe across the country. Big factor for work home scenario",
  },
  {
    id: 3,
    image: Comprehensive,
    cardHeading: "Comprehensive healthcare under one umbrella ",
    cardContent:
      "Not just Annual Health Checks but you can expect all healthcare needs under one umbrella.",
  },
  {
    id: 4,
    image: Health,
    cardHeading: "Health Analytics",
    cardContent:
      " Our health analytics reflect the current health scenario of an employee or the company and suggest recommendations to improve health.",
  },
];
const HRperspective = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <h2 className={classes.heading}>
          Understanding the problem with HR perspective
        </h2>
        <Grid container spacing={12}>
          {PrespectiveDetails.map((Details) => {
            return (
              <Grid item xs={12} sm={6} md={6} key={Details.id}>
                <Avatar
                  className={classes.avatar}
                  alt="Remy Sharp"
                  src={Details.image}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className={classes.allignment}
                >
                  {Details.cardHeading}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  className={classes.allignment1}
                >
                  {Details.cardContent}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default HRperspective;
