import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import BusinessStandard from "../../assets/BusinessStandard.png";
import Dc from "../../assets/DC.png";
import Economic from "../../assets/Economic-Times.png";
import Forbes from "../../assets/Forbes.png";
import Fox from "../../assets/Fox-News.png";
import Hindu from "../../assets/hindu.png";
import Npr from "../../assets/npr.webp";

import "../slickSlider/Slick.css";

const NewsLetterSlick = [
  {
    id: 1,
    image: BusinessStandard,
    pathTo:
      "https://www.deccanchronicle.com/140323/lifestyle-offbeat/article/date-clinton",
  },
  {
    id: 2,
    image: Npr,
    pathTo:
      "https://www.deccanchronicle.com/140323/lifestyle-offbeat/article/date-clinton",
  },
  {
    id: 3,
    image: Economic,
    pathTo:
      "https://economictimes.indiatimes.com/industry/services/education/isb-alumni-win-hult-prize-1-million-funding-from-clinton-global-initiative/articleshow/43353236.cms",
  },
  {
    id: 4,
    image: Forbes,
    pathTo:
      "https://www.forbes.com/sites/devinthorpe/2014/10/22/president-clinton-presents-hult-prize-to-indian-social-entrepreneurs/?sh=4fca75f632a3",
  },
  {
    id: 5,
    image: Fox,
    pathTo:
      "https://www.foxnews.com/tech/social-venture-looks-to-boost-health-care-in-the-worlds-slums",
  },
  {
    id: 6,
    image: Hindu,
    pathTo:
      "https://www.thehindu.com/features/metroplus/society/out-of-the-box-service/article6489099.ece",
  },
  {
    id: 7,
    image: Dc,
    pathTo:
      "https://www.deccanchronicle.com/140323/lifestyle-offbeat/article/date-clinton",
  },
];

const useStyles = makeStyles((theme) => ({
  resize: {
    paddingTop: "2em !important",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    marginBottom: "2em !important",
  },
  adjust: {
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      margin: "auto !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "130% !important",
      margin: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      margin: "auto !important",
    },
  },
}));

const SlickOne = () => {
  const classes = useStyles();

  var settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {NewsLetterSlick.map((Details) => {
          return (
            <div className={classes.resize} key={Details.id}>
              <h3 className={classes.size}>
                <a target="_blank" rel="noreferrer" href={Details.pathTo}>
                  <img
                    src={Details.image}
                    alt="clients logo"
                    className={classes.adjust}
                  />
                </a>
              </h3>
            </div>
          );
        })}
      </Slider>
    </>
  );
};
export default SlickOne;
