import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import OurDelight_1 from "../../../assets/OurDelight_1.png";
import OurDelight_2 from "../../../assets/OurDelight_2.png";
import OurDelight_3 from "../../../assets/OurDelight_3.png";
import OurDelight_4 from "../../../assets/OurDelight_4.png";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    marginTop: "-2.5em !important",
    backgroundColor: "#FAE8FF !important",
    height: "103%",
    [theme.breakpoints.down("md")]: {
      alignItems: " center !important",
    },
  },
  blogsContainer: {
    paddingTop: theme.spacing(6),
  },
  avatar: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    marginLeft: "4.1em !important",
    marginTop: "-2.5em !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "6em !important",
    },
  },
  col: {
    margin: theme.spacing(2),
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  allignment: {
    textAlign: "center !important",
  },
  bottom: {
    paddingBottom: "1em !important",
  },
  main: {
    textAlign: "center !important",
    fontSize: "1em !important",
    lineHeight: "1.5rem !important",
  },
  heading: {
    marginLeft: "3.5em !important",
    fontWeight: "600 !important",
    fontSize: "1.7em !important",
    paddingBottom: "1em !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "0em !important",
    },
  },
}));
const OurDelightDetails = [
  {
    id: 1,
    image: OurDelight_1,
    cardContent:
      "Conceptualized and executed the covid care program for corporations of every size nationwide. Also delivered over covid vaccines for all one lakh plus priority covid vaccines for all our corporates..",
  },
  {
    id: 2,
    image: OurDelight_2,
    cardContent:
      "Provided Oxygen concentrators and stored for backup in case of emergencies,Our services ensured zero fatality and fast recoveries!.",
  },
  {
    id: 3,
    image: OurDelight_3,
    cardContent:
      "Effective post covid care for COVID positive employees included 24/7 nurse on-call,virtual consults at their homes/hospitals..",
  },
  {
    id: 4,
    image: OurDelight_4,
    cardContent:
      " Doctor on Call Health coaches,dieticians, happiness coaches, diagnostics.ambulance services. stress management and women healthcare made available pan India.",
  },
];
const Ourdelight = () => {
  const classes = useStyles();
  return (
    <div className={classes.bottom}>
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        className={classes.heading}
      >
        Our Delight
      </Typography>
      <Container maxWidth="lg" className={classes.blogsContainer}>
        <Grid container spacing={8}>
          {OurDelightDetails.map((Details) => {
            return (
              <Grid item xs={12} sm={6} md={3}>
                <Avatar
                  className={classes.avatar}
                  alt="Remy Sharp"
                  src={Details.image}
                  sx={{ width: 76, height: 76 }}
                />
                <Card className={classes.card}>
                  <CardContent className={classes.col}>
                    <Typography variant="body2" color="textSecondary">
                      <p className={classes.main}>{Details.cardContent}</p>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Ourdelight;
