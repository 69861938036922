import React from "react";
import Ourofferings from "./Ourofferings";
import OurOfferingBanner from "./OurofferingsBanner";

const OfferingsWithBanner = () => {
  return (
    <>
      <OurOfferingBanner />
      <Ourofferings />
    </>
  );
};

export default OfferingsWithBanner;
