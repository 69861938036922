import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/Logo.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { fontFamily } from "@mui/system";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: "11111 !important",

    boxShadow: " 0 8px 6px -6px #999 !important",
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "2.8em !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em !important",
    },
  },
  logo: {
    marginLeft: "4em",

    height: "7em ",
    [theme.breakpoints.down("md")]: {
      height: "5em",

      marginLeft: "-1em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "3em",
    },
    logoContainer: {
      padding: " 0 !important",

      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
  tabContainer: {
    marginLeft: "auto ",
    indicatorColor: "primary !important",
    minHeight: "50px !important",
    backgroundColor: "white !important",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: "10px !important",
    // marginLeft:'10px !important',
    backgroundColor: "white !important",
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px !important",
    marginLeft: "50px !important",
    marginRight: "25px !important",
    height: "45px !important",
    backgroundColor: "#4caf50  !important",
  },
  box: {
    boxShadow: " 0 8px 6px -6px #999 !important",
    zIndex: "theme.zindex.modal + 1 !important",
  },
  drawerIconContainer: {
    marginLeft: "auto !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  drawerIcon: {
    height: "40px !important",
    width: "40px !important",
  },
  drawerItem: {
    ...theme.typography.tab,
    marginTop: 1,
  },
  drawer: {
    backgroundColor: theme.orange,
  },
  drawerItemDemo: {
    backgroundColor: theme.blue,
  },
  drawerItemSelected: {
    opacity: "1 !important",
  },
  drawer: {
    width: 180,
    marginTop: "8px !important",
    marginRight: "30px",
  },
  drawerItemSchedule: {
    backgroundColor: "#4caf50 !important",
  },
  tabing: {
    marginTop: "0.8em !important",
    fontFamily: "Raleway !important",
    textTransform: "none !important",
    fontWeight: "500 !important",
    fontSize: "1rem !important",
    color: "#4B4D4D !important",
    marginLeft: "0.8em",
    marginRight: "0.8em",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (e, value) => {
    props.setValue(value);
  };
  const routes = [
    { name: "Home", link: "/", activeIndex: 0 },
    { name: "Our Offerings", link: "/ourofferings", activeIndex: 1 },
    { name: "For Individual's", activeIndex: 2 },
    { name: "About Us", link: "/aboutus", activeIndex: 3 },
    { name: "Blogs", link: "/blogs", activeIndex: 4 },

    { name: "Login", activeIndex: 5 },
  ];
  useEffect(() => {
    [...routes].forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (props.value !== route.activeIndex) {
            props.setValue(route.activeIndex);
          }
          break;
        default:
          break;
      }
    });
  }, [props.value, routes, props]);

  const tabs = (
    <React.Fragment>
      <Tabs
        value={props.value}
        onChange={handleChange}
        className={classes.tabContainer}
        indicatorColor="secondary"
      >
        {routes.map((route, index) => {
          const { name } = route;
          // if (name === "Blogs") {
          //   return (
          //     <a
          //       className={classes.tabing}
          //       href="https://www.nhassurance.com/blogs"
          //       target="_blank"
          //     >
          //       {name}
          //     </a>
          //   );
          // }
          if (name === "Login") {
            return (
              <a
                className={classes.tabing}
                href="https://customer.nanohealth.in/users/sign_in"
                rel="noreferrer"
                target="_blank"
              >
                {name}
              </a>
            );
          } else if (name === "For Individual's") {
            return (
              <a
                className={classes.tabing}
                href={"https://www.nhassurance.com/"}
                rel="noreferrer"
                target="_blank"
              >
                {name}
              </a>
            );
          } else {
            return (
              <Tab
                key={`${route}${index}`}
                className={classes.tab}
                component={Link}
                to={route.link}
                label={route.name}
              />
            );
          }
        })}
      </Tabs>
      <Button
        variant="contained"
        color="success"
        size="small"
        className={classes.button}
        to="scheduledemo"
        LinkComponent={Link}
      >
        {" "}
        Schedule Demo
      </Button>
    </React.Fragment>
  );
  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        position="fixed"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <>
          <div className={classes.toolbarMargin} />
          <List disablePadding className={classes.drawer}>
            {routes.map((route) => {
              const { name } = route;
              // if (name === "Blogs") {
              //   return (
              //     <ListItem divider>
              //       <a
              //         className={classes.tab}
              //         href="https://www.nhassurance.com/blogs"
              //         target="_blank"
              //       >
              //         {route.name}
              //       </a>
              //     </ListItem>
              //   );
              // } else
              if (name === "Login") {
                return (
                  <ListItem divider>
                    <a
                      className={classes.tab}
                      href="https://customer.nanohealth.in/users/sign_in"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {name}
                    </a>
                  </ListItem>
                );
              } else if (name === "For Individual's") {
                return (
                  <ListItem divider>
                    <a
                      className={classes.tab}
                      href="https://www.nhassurance.com/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {name}
                    </a>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    divider
                    key={`${route} ${route.activeIndex}`}
                    button
                    component={Link}
                    to={route.link}
                    selected={props.value === route.activeIndex}
                    onClick={() => {
                      setOpenDrawer(false);
                      props.setValue(route.activeIndex);
                    }}
                  >
                    <ListItemText
                      className={
                        props.value === route.activeIndex
                          ? [classes.drawerItem, classes.drawerItemSelected]
                          : classes.drawerItem
                      }
                      disableTypography
                    >
                      {route.name}{" "}
                    </ListItemText>
                  </ListItem>
                );
              }
            })}

            <ListItem
              onClick={() => {
                setOpenDrawer(false);
                props.setValue(6);
              }}
              divider
              button
              component={Link}
              className={classes.drawerItemSchedule}
              to="/scheduledemo"
              selected={props.value === 6}
            >
              <ListItemText disableTypography>Schedule Demo</ListItemText>
            </ListItem>
          </List>
        </>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <Button
              element={Link}
              to=""
              disableRipple
              onClick={() => props.setValue(0)}
              className={classes.logoContainer}
            >
              {" "}
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
};

export default Header;
