import React from "react";
import Disease from "../../../assets/Diseasecard.webp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  adjust: {
    position: "absolute !important",
    width: "18% !important",
    marginLeft: "64rem !important",
    marginTop: "19em !important",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
}));

const DiseaseManagementcard = () => {
  const classes = useStyles();
  return (
    <div>
      <a
        href="https://www.nhassurance.com/diseases-management"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Disease} alt="clients logo" className={classes.adjust} />
      </a>
    </div>
  );
};

export default DiseaseManagementcard;
