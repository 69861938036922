import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import Arcesium from "../../assets/Arcesium.webp";
import DEShawa from "../../assets/DEShawa.webp";
import Bharat from "../../assets/Bharat.webp";
import Red from "../../assets/Red.webp";
import Natco from "../../assets/NATCO.webp";
import Karvya from "../../assets/Karvya.webp";
import Isb from "../../assets/ISB.webp";
import ValueLabs from "../../assets/ValueLabs.webp";
import divami from "../../assets/divami.webp";
import Hyderabad from "../../assets/Hyderabad.webp";
import Bangalore from "../../assets/Bangalore.webp";

import "../slickSlider/Slick.css";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  resize: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.7rem !important",
    marginTop: "3% !important",
    color: "#8600C9 !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0 !important",
      marginTop: "0.5em !important",
    },
  },
  adjust: {
    width: "100% !important",
    paddingBottom: "2% !importsnt",
    paddingTop: "2% !important",
  },
  size: {
    marginLeft: "0 !important",
    marginRight: "0 !important",
  },
  minimize: {
    width: "80% !important",
    [theme.breakpoints.down("md")]: {
      width: "80% !important",
      margin: "auto !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "70% !important",
      margin: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90% !important",
      margin: "auto !important",
    },
  },
}));

const DetailsOfSlick = [
  {
    id: 1,
    image: Arcesium,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 2,
    image: DEShawa,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 3,
    image: Bharat,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 4,
    image: Red,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 5,
    image: Natco,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 6,
    image: Karvya,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    image: Isb,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 8,
    image: ValueLabs,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 9,
    image: divami,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 10,
    image: Hyderabad,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
  {
    id: 11,
    image: Bangalore,
    pathTo:
      "https://www.business-standard.com/article/companies/nanohealth-expects-to-launch-operations-by-jan-114101401054_1.html",
  },
];

const Slick = () => {
  const classes = useStyles();

  var settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Typography className={classes.resize} variant="h2" component="div">
        Our Clients
      </Typography>
      <Slider {...settings}>
        {DetailsOfSlick.map((Details) => {
          return (
            <h3 className={classes.size} key={Details.id}>
              <a target="_blank" rel="noreferrer" href={Details.pathTo}>
                <img
                  src={Details.image}
                  alt="clients logo"
                  className={classes.minimize}
                />
              </a>
            </h3>
          );
        })}
      </Slider>
    </div>
  );
};
export default Slick;
