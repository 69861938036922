import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Maps from "../../../assets/Map.png";

const useStyles = makeStyles((theme) => ({
  position: {
    width: "50% !important",
    color: "#white !important",
    lineHeight: "2em !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    width: "40% important",
    marginTop: "3em !important",
    marginLeft: "3.8em !important",
    fontWeight: "600 !important",
    fontSize: "1.7em !important",

    [theme.breakpoints.down("md")]: {
      textAlign: "center !important",
      marginTop: "0em !important",
      marginBottom: "0em !important",
      marginLeft: "0em !important",
    },
  },
  position1: {
    fontSize: "1em !important",
    fontFamily: "Raleway !important",
    lineHeight: "2rem !important",
    fontFamily: "Raleway !important",
    marginLeft: "6.5em !important",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      textAlign: "center !important",
      fontWeight: "500 !important",
      width: "80%",
      marginLeft: "2em !important",
    },
  },
}));
const images = [
  {
    imgPath: Maps,
    text: "Recent key wins ? ",
    text1:
      "If your aim is to provide holistic healthcare and go beyond just the standard healthcare then you and us share a common goal.",
    text2:
      "We are a digital healthcare company focused at working closely with corporations to understand their vision on employee healthcare, customize the offering and execute nationwide given most employees have been working from home since a while",
  },
];
const Premium = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={6}>
          <h2 className={classes.position}>Recent key wins ?</h2>
          <ul className={classes.position1}>
            <li>Covid care program for all corporations nationwide.</li>
            <li> One lakh + priority covid vaccines for all our corporates</li>
            <li> Provided post covid care for 1000+ employees</li>
            <li>5000+ Oxygen concentrators delivered</li>
            <li>Medical care services at sale</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <Box
            sx={{
              maxWidth: "80%",
              flexGrow: 1,
              marginBottom: "3em ",
              marginLeft: "4em !important",
            }}
          >
            <Paper
              square
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                pl: 1,
              }}
            >
              <Typography>{images[activeStep].label}</Typography>
            </Paper>
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                  />
                ) : null}
              </div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Premium;
