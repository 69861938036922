import React from "react";
import Lab from "../../../assets/LabCard.webp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  adjust: {
    position: "absolute !important",
    width: "18% !important",
    marginLeft: "64rem !important",
    marginTop: "36em !important",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
}));

const Labcard = () => {
  const classes = useStyles();
  return (
    <div>
      <a
        href="https://www.nhassurance.com/lab-test"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Lab} alt="clients logo" className={classes.adjust} />
      </a>
    </div>
  );
};

export default Labcard;
