import React from "react";
import { makeStyles } from "@mui/styles";

import Covid from "../../assets/covidbanner.webp";
const useStyles = makeStyles((theme) => ({
  logo1: {
    width: "100% ",
  },
}));

const Covidbanner = () => {
  const classes = useStyles();

  return (
    <>
      {" "}
      <a target="_blank" rel="noreferrer" href={"https://www.nhassurance.com/"}>
        <img src={Covid} alt="clients logo" className={classes.logo1} />
      </a>
    </>
  );
};

export default Covidbanner;
