import React from "react";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import MobileVideo from "../../assets/MobileVideo.mp4";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: " relative",
    paddingTop: "56.25%",
    borderRadius: "10px !important",
  },
  reactplayer: {
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "10px !important",
  },
  content: {
    margin: theme.spacing(0),
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    fontSize: "1.7em !important",
    margin: "1em !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  back: {
    padding: theme.spacing("3em", "2em", "2em", "6.3em"),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing("1em", "1em", "0em", "1em"),
    },
  },
  heading: {
    fontFamily: "Raleway !important",
    fontSize: "1em !important",
    color: "#8600c9 !important",
    margin: "1em !important",
    width: "90% !important",
    lineHeight: "2em !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  mainheading: {
    fontFamily: "Raleway !important",
    fontSize: "1.5em !important",

    fontWeight: "600 !important",
    color: "#8600C9 !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  subheading: {
    fontFamily: "Raleway !important",
    fontSize: "1em !important",
    width: "75% !important",

    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "2.5em !important",
    },
  },
  main: {
    lineSpacing: "1em !important",
  },
}));

const Drive = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.back}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={6}>
            <div className={classes.playerWrapper}>
              <ReactPlayer
                className={classes.reactplayer}
                url={MobileVideo}
                width="95%"
                height="95%"
                controls={true}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <Typography
              className={classes.mainheading}
              variant="h5"
              gutterBottom
              component="div"
            >
              Drive measurable health impact{" "}
            </Typography>
            <Typography
              className={classes.subheading}
              variant="h6"
              gutterBottom
              component="div"
            >
              We enable the employees to monitor their health metrics on
              periodic basis on digital health platform and nudge them to change
              their lifestyle habits with our trusted care team
            </Typography>
            <Typography
              className={classes.heading}
              variant="h7"
              gutterBottom
              component="div"
            >
              <ul className={classes.main}>
                <li>Health assessment and trend monitoring</li>
                <li>Personalized alerts with our recommendation engine</li>
                <li>
                  Connect with Health Coach/ Doctor/ Nutritionist /
                  Diagnosticlabs
                </li>
                <li>Personalized care program for periodic monitoring</li>
                <li> Weekly interactive session to drive change habits</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Drive;
