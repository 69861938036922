import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Img1 from "../../../assets/img2.png";

const useStyles = makeStyles((theme) => ({
  position: {
    width: "50% !important",
    color: "#white !important",
    lineHeight: "2em !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    width: "40% important",
    marginTop: "3em !important",
    marginLeft: "3.7em !important",
    fontWeight: "600 !important",
    fontSize: "1.7em !important",

    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      marginTop: "0em !important",
      marginBottom: "0em !important",
      marginLeft: "0em !important",
    },
  },
  position1: {
    fontSize: "1em !important",
    fontFamily: "Raleway !important",
    lineHeight: "1.5rem !important",
    fontFamily: "Raleway !important",
    marginLeft: "6em !important",
    width: "70%",

    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      fontWeight: "500 !important",
      width: "auto",
      marginLeft: "0em !important",
    },
  },
}));
const images = [
  {
    imgPath: Img1,
    text: "Why us? ",
    text1:
      "If your aim is to provide holistic healthcare and go beyond just the standard healthcare then you and us share a common goal.",
    text2:
      "We are a digital healthcare company focused at working closely with corporations to understand their vision on employee healthcare, customize the offering and execute nationwide given most employees have been working from home since a while",
  },
];
const Premium = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={6}>
          <h2 className={classes.position}>Why us?</h2>
          <p className={classes.position1}>
            {" "}
            Premium and organized healthcare at economical price points
          </p>
          <p className={classes.position1}>
            Our clients get priority services with all our healthcare partners
            and also provide dedicated hours for you when needed. Sometimes we
            also book the entire lab for bulk employee testing.
          </p>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <Box sx={{ maxWidth: "100%", flexGrow: 1, marginBottom: "1em " }}>
            <Paper
              square
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                pl: 1,
              }}
            >
              <Typography>{images[activeStep].label}</Typography>
            </Paper>
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                  />
                ) : null}
              </div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Premium;
