import React from "react";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: " relative",
    paddingTop: "56.25%",
  },
  reactplayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    textAlign: "center !important",
    padding: theme.spacing(5),
    margin: theme.spacing(0),
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
  },
  back: {
    padding: theme.spacing("0em", "3em", "3em", "6.3em"),
    backgroundColor: "#FAE8FF !important",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing("0em", "1em", "0em", "1em"),
      textAlign: "center !important",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center !important",
    },
  },
  heading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.7rem !important",
    color: "#8600C9 !important",

    padding: theme.spacing(3),
    margin: theme.spacing(0),
  },
}));
const YoutubeLinks = [
  {
    id: 1,
    pathTo: "https://www.youtube.com/watch?v=5_CfPls27Lo",
  },
  {
    id: 2,
    pathTo: "https://www.youtube.com/watch?v=phlj8SLY_WM&t=50s",
  },
  {
    id: 3,
    pathTo: "https://www.youtube.com/watch?v=5yVRFdZu-tg&t=50s",
  },
];
const AssuranceTalk = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.back}>
        <Typography className={classes.heading} variant="h2" component="div">
          Assurance Talk
        </Typography>
        <Grid container spacing={2}>
          {YoutubeLinks.map((Details) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={Details.id}>
                <div className={classes.playerWrapper}>
                  <ReactPlayer
                    className={classes.reactplayer}
                    url={Details.pathTo}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};
export default AssuranceTalk;
