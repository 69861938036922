import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Nanologo from "../../assets/Nanologo.png";
import NHlogo from "../../assets/NHlogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Google from "../../assets/playStorEgoogle.png";
import Apple from "../../assets/appStoreapple.png";
import "../../index.css";

const useStyles = makeStyles((theme) => ({
  space: {
    margin: theme.spacing(0),
    // backgroundColor:"#4caf50  !important"
  },
  logo: {
    height: "35px !important",
    marginTop: "1em !important",
  },
  logo1: {
    height: "35px !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1em !important",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0em !important",
    },
  },
  col: {
    color: "black !important",
    lineHeight: "1.8em !important",
    padding: "0 !important",
    fontWeight: "400 ",
  },
  social: {
    width: "30rem !important",
  },
  gap: {
    color: "black !important",
    lineHeight: "1.8em !important",
    fontWeight: "400  !important",
    marginTop: "1em !important",
  },
  size: {
    margin: "4px !important",
    height: "2em !important",
    width: "1.2em !important",
  },
  adjust: {
    width: "3em !important",
  },
  alternative: {
    height: "35px !important",
    marginTop: "1em !important",
  },
  alternative1: {
    height: "35px !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1em ",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0em ",
    },
  },
  heading: {
    fontWeight: "bolder !important",
    paddingBottom: "0.5em !important",
  },
  date: {
    textAlign: "center ",
    fontWeight: "bolder !important",
    fontFamily: "Raleway !important",
    padding: "1em !important",
  },
  main: {
    padding: "0 !important",
    zindex: "1111 !important",
  },
  footer: {
    zIndex: "11111 !important",
    width: "100% !important",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.main}>
        <Box
          px={{ xs: 3, sm: 10 }}
          py={{ xs: 5, sm: 10 }}
          bgcolor="text.secondary"
          color="black"
          className="gradiant"
        >
          <Container maxWidth="lg" className="gradiant">
            <Grid container spacing={6} className="gradiant">
              <Grid item xs={12} sm={4} md={2.4}>
                <Box borderBottom={1} className={classes.heading}>
                  Useful Links
                </Box>
                <ul>
                  <Link to="/" color="inherit" className={classes.gap}>
                    Home
                  </Link>
                  <Box></Box>
                  <Link to="/aboutus" color="inherit" className={classes.gap}>
                    About Us
                  </Link>
                  <Box></Box>
                  <a
                    href="https://www.nhassurance.com/disease-management-program/covid-care"
                    rel="noreferrer"
                    color="inherit"
                    className={classes.gap}
                    target="_blank"
                  >
                    Pakages
                  </a>
                  <Box></Box>
                  <a
                    href="https://www.nhassurance.com/"
                    rel="noreferrer"
                    color="inherit"
                    className={classes.gap}
                    target="_blank"
                  >
                    For Individual's
                  </a>
                  <Box></Box>
                  <Link to="/blogs" color="inherit" className={classes.gap}>
                    Blogs
                  </Link>
                  <Box></Box>
                  <a
                    href="https://www.nhassurance.com/contact-us.html"
                    rel="noreferrer"
                    color="inherit"
                    className={classes.gap}
                    target="_blank"
                  >
                    Contact Us
                  </a>
                  <Box></Box>
                </ul>
              </Grid>
              <Grid item xs={12} sm={4} md={2.4}>
                <Box borderBottom={1} className={classes.heading}>
                  Our Brands
                </Box>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={"https://nanohealth.in/"}
                  >
                    <img
                      src={Nanologo}
                      alt="clients logo"
                      className={classes.logo}
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={"https://www.nhcircle.com/"}
                  >
                    <img
                      src={NHlogo}
                      alt="clients logo"
                      className={classes.logo1}
                    />
                  </a>
                </div>
                <Box></Box>
              </Grid>
              <Grid item xs={12} sm={4} md={2.4}>
                <Box borderBottom={1} className={classes.heading}>
                  Policies
                </Box>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className={classes.col}
                      href={"https://nanohealth.in/privacy_policy.html"}
                      color="inherit"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <Box></Box>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className={classes.col}
                      href={"https://nanohealth.in/terms_conditions.html"}
                      color="inherit"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                </ul>
                <Box></Box>
              </Grid>
              <Grid item xs={12} sm={4} md={2.4}>
                <Box borderBottom={1} className={classes.heading}>
                  Follow Us
                </Box>
                <div>
                  <a
                    href="https://www.facebook.com/NanoHealth.in/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon className={classes.size} />
                  </a>
                  <a
                    href="https://twitter.com/nanohealth1"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TwitterIcon className={classes.size} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCZ5_SJdc3d7yTueH8rVBAOw"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <YouTubeIcon className={classes.size} />
                  </a>
                  <a
                    href="https://in.linkedin.com/company/nanohealth"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <LinkedInIcon className={classes.size} />
                  </a>
                  <a
                    href="https://www.instagram.com/nhassurance/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon className={classes.size} />
                  </a>
                </div>
                <Box></Box>
              </Grid>
              <Grid item xs={12} sm={4} md={2.4}>
                <Box borderBottom={1} className={classes.heading}>
                  Download App
                </Box>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://play.google.com/store/apps/details?id=in.nanohealth.user"
                    }
                  >
                    <img
                      src={Google}
                      alt="clients logo"
                      className={classes.alternative}
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://play.google.com/store/apps/details?id=in.nanohealth.user"
                    }
                  >
                    <img
                      src={Apple}
                      alt="clients logo"
                      target="_blank"
                      className={classes.alternative1}
                    />
                  </a>
                </div>
                <Box></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className={classes.date}>
                  &reg;{new Date().getFullYear()} NanoHealth. All Rights
                  Reserved
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </footer>
  );
};

export default Footer;
