import React from "react";
import Banner from "./Banner";
import HRperspective from "./HRperspective";
import Ourdelight from "./Ourdelight";
import Howdowedoit from "./Howdowedoit";
import Slider from "./Slider";
import Whyus from "./Whyus";
import Recentkey from "./Recentkey";
import Premium from "./Premium";
import SlickOne from "../../slickSlider/SlickOne";

const About = () => {
  return (
    <>
      <Banner />
      <Slider />
      <HRperspective />
      <Ourdelight />
      <Howdowedoit />
      <Premium />
      <Whyus />
      <Recentkey />
      <SlickOne />
    </>
  );
};

export default About;
