import { createTheme } from "@mui/material/styles";

const arcWhite = "#fff";

const arcOrange = "#8600C9";

export default createTheme({
  palette: {
    common: {
      white: arcWhite,
      orange: arcOrange,
    },

    primary: {
      main: `${arcWhite}`,
    },
    secondary: {
      main: `${arcOrange}`,
    },
  },
  typography: {
    tab: {
      fontFamily: "Raleway !important",
      textTransform: "none !important",
      fontWeight: "500 !important",
      fontSize: "1rem !important",
      color: "#4B4D4D !important",
    },
    estimate: {
      fontFamily: "Raleway !important",
      fontSize: "1rem !important",
      textTransform: "none !important",
    },
  },
});
