import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "white !important",
    marginLeft: "3em !important",
    fontFamily: "Raleway !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center ",
      width: "100% !important",
      marginLeft: "0 !important",
      fontSize: "1em !important",
      marginTop: "0.5em !important",
    },
  },
  main: {
    backgroundColor: "#29b6f6 !important",
    padding: theme.spacing(3),
  },
}));

const Banner = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Typography
        className={classes.heading}
        variant="h5"
        component="div"
        gutterBottom
      >
        Why Assurance by NanoHealth
      </Typography>
    </div>
  );
};
export default Banner;
