import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  date: {
    backgroundColor: "#8600C9 !important",
    textAlign: "center !important",
    lineHeight: "1.4em !important",
    fontWeight: "600 !important",
    color: "#FFF   !important",
    height: "75px !important",
    width: "60px !important",
    marginTop: "-1.8em !important",
    marginLeft: "1.2em !important",
  },
  pad: {
    padding: "0 !important",
  },
}));

const TimeForFullscreen = (props) => {
  const classes = useStyles();
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let showDate = new Date(props.date);
  let date = showDate.getDate();
  let monthName = months[showDate.getMonth()];
  let year = showDate.getFullYear();
  return (
    <div className={classes.pad}>
      <ul className={classes.date}>
        <li>{date}</li>
        <li>{monthName}</li>
        <li>{year}</li>
      </ul>
    </div>
  );
};

export default TimeForFullscreen;
