import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Img1 from "../../../assets/img1.png";

const useStyles = makeStyles((theme) => ({
  position: {
    color: "#white !important",
    fontSize: "1.7rem !important",
    lineHeight: "2em !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    fontWeight: "600 !important",
    marginTop: "3em !important",
    marginLeft: "0em !important",
    [theme.breakpoints.down("md")]: {
      textAlign: " center !important ",
      marginTop: "0em !important",
      marginBottom: "0em !important",
    },
  },
  position1: {
    fontSize: "1em !important",
    lineHeight: "1.5rem !important",
    fontFamily: "Raleway !important",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      textAlign: " center !important",
      fontWeight: "500 !important",
      width: "auto",
      margin: theme.spacing("1em"),
    },
  },
}));
const images = [
  {
    imgPath: Img1,
    text: "Who are we ?",
    text1:
      "If your aim is to provide holistic healthcare and go beyond just the standard healthcare then you and us share a common goal.",
    text2:
      "We are a digital healthcare company focused at working closely with corporations to understand their vision on employee healthcare, customize the offering and execute nationwide given most employees have been working from home since a while",
  },
];
const Slider = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={6}>
          <Box sx={{ maxWidth: "100%", flexGrow: 1, marginBottom: "1em " }}>
            <Paper
              square
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                pl: 1,
              }}
            >
              <Typography>{images[activeStep].label}</Typography>
            </Paper>
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                  />
                ) : null}
              </div>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <h2 className={classes.position}>Who are we ?</h2>
          <p className={classes.position1}>
            'If your aim is to provide holistic healthcare and go beyond just
            the standard healthcare then you and us share a common goal.'
          </p>
          <p className={classes.position1}>
            'We are a digital healthcare company focused at working closely with
            corporations to understand their vision on employee healthcare,
            customize the offering and execute nationwide given most employees
            have been working from home since a while'
          </p>
        </Grid>
      </Grid>
    </>
  );
};
export default Slider;
