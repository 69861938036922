import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  names: {
    display: "flex !important",
  },
}));

const Countrycode = () => {
  const classes = useStyles();

  const [age, setAge] = React.useState();
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className={classes.names}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl sx={{ minWidth: 170 }}>
          <InputLabel>countryCode</InputLabel>
          <Select
            label="countryCode"
            placeholder="country"
            variant="outlined"
            value={age}
            onChange={handleChange}
          >
            <MenuItem data-countryCode="GB" value="44">
              Norway (+47)
            </MenuItem>
            <MenuItem data-countryCode="US" value="1">
              UK (+44)
            </MenuItem>

            <MenuItem data-countryCode="DZ" value="213">
              Algeria (+213)
            </MenuItem>
            <MenuItem data-countryCode="AD" value="376">
              Andorra (+376)
            </MenuItem>
            <MenuItem data-countryCode="AO" value="244">
              Angola (+244)
            </MenuItem>
            <MenuItem data-countryCode="AI" value="1264">
              Anguilla (+1264)
            </MenuItem>

            <MenuItem data-countryCode="AM" value="374">
              Armenia (+374)
            </MenuItem>
            <MenuItem data-countryCode="AW" value="297">
              Aruba (+297)
            </MenuItem>
            <MenuItem data-countryCode="AU" value="61">
              Australia (+61)
            </MenuItem>
            <MenuItem data-countryCode="AT" value="43">
              Austria (+43)
            </MenuItem>

            <MenuItem data-countryCode="BS" value="1242">
              Bahamas (+1242)
            </MenuItem>
            <MenuItem data-countryCode="BH" value="973">
              Bahrain (+973)
            </MenuItem>
            <MenuItem data-countryCode="BD" value="880">
              Bangladesh (+880)
            </MenuItem>
            <MenuItem data-countryCode="BB" value="1246">
              Barbados (+1246)
            </MenuItem>
            <MenuItem data-countryCode="BY" value="375">
              Belarus (+375)
            </MenuItem>
            <MenuItem data-countryCode="BE" value="32">
              Belgium (+32)
            </MenuItem>
            <MenuItem data-countryCode="BZ" value="501">
              Belize (+501)
            </MenuItem>
            <MenuItem data-countryCode="BJ" value="229">
              Benin (+229)
            </MenuItem>
            <MenuItem data-countryCode="BM" value="1441">
              Bermuda (+1441)
            </MenuItem>
            <MenuItem data-countryCode="BT" value="975">
              Bhutan (+975)
            </MenuItem>
            <MenuItem data-countryCode="BO" value="591">
              Bolivia (+591)
            </MenuItem>

            <MenuItem data-countryCode="BW" value="267">
              Botswana (+267)
            </MenuItem>
            <MenuItem data-countryCode="BR" value="55">
              Brazil (+55)
            </MenuItem>
            <MenuItem data-countryCode="BN" value="673">
              Brunei (+673)
            </MenuItem>
            <MenuItem data-countryCode="BG" value="359">
              Bulgaria (+359)
            </MenuItem>

            <MenuItem data-countryCode="BI" value="257">
              Burundi (+257)
            </MenuItem>
            <MenuItem data-countryCode="KH" value="855">
              Cambodia (+855)
            </MenuItem>
            <MenuItem data-countryCode="CM" value="237">
              Cameroon (+237)
            </MenuItem>
            <MenuItem data-countryCode="CA" value="1">
              Canada (+1)
            </MenuItem>

            <MenuItem data-countryCode="CL" value="56">
              Chile (+56)
            </MenuItem>
            <MenuItem data-countryCode="CN" value="86">
              China (+86)
            </MenuItem>
            <MenuItem data-countryCode="CO" value="57">
              Colombia (+57)
            </MenuItem>
            <MenuItem data-countryCode="KM" value="269">
              Comoros (+269)
            </MenuItem>
            <MenuItem data-countryCode="CG" value="242">
              Congo (+242)
            </MenuItem>

            <MenuItem data-countryCode="CR" value="506">
              Costa Rica (+506)
            </MenuItem>
            <MenuItem data-countryCode="HR" value="385">
              Croatia (+385)
            </MenuItem>
            <MenuItem data-countryCode="CU" value="53">
              Cuba (+53)
            </MenuItem>

            <MenuItem data-countryCode="DK" value="45">
              Denmark (+45)
            </MenuItem>
            <MenuItem data-countryCode="DJ" value="253">
              Djibouti (+253)
            </MenuItem>
            <MenuItem data-countryCode="DM" value="1809">
              Dominica (+1809)
            </MenuItem>

            <MenuItem data-countryCode="EC" value="593">
              Ecuador (+593)
            </MenuItem>
            <MenuItem data-countryCode="EG" value="20">
              Egypt (+20)
            </MenuItem>

            <MenuItem data-countryCode="ER" value="291">
              Eritrea (+291)
            </MenuItem>
            <MenuItem data-countryCode="EE" value="372">
              Estonia (+372)
            </MenuItem>
            <MenuItem data-countryCode="ET" value="251">
              Ethiopia (+251)
            </MenuItem>

            <MenuItem data-countryCode="FJ" value="679">
              Fiji (+679)
            </MenuItem>
            <MenuItem data-countryCode="FI" value="358">
              Finland (+358)
            </MenuItem>
            <MenuItem data-countryCode="FR" value="33">
              France (+33)
            </MenuItem>

            <MenuItem data-countryCode="GA" value="241">
              Gabon (+241)
            </MenuItem>
            <MenuItem data-countryCode="GM" value="220">
              Gambia (+220)
            </MenuItem>
            <MenuItem data-countryCode="GE" value="7880">
              Georgia (+7880)
            </MenuItem>
            <MenuItem data-countryCode="DE" value="49">
              Germany (+49)
            </MenuItem>
            <MenuItem data-countryCode="GH" value="233">
              Ghana (+233)
            </MenuItem>
            <MenuItem data-countryCode="GI" value="350">
              Gibraltar (+350)
            </MenuItem>
            <MenuItem data-countryCode="GR" value="30">
              Greece (+30)
            </MenuItem>
            <MenuItem data-countryCode="GL" value="299">
              Greenland (+299)
            </MenuItem>
            <MenuItem data-countryCode="GD" value="1473">
              Grenada (+1473)
            </MenuItem>

            <MenuItem data-countryCode="GU" value="671">
              Guam (+671)
            </MenuItem>

            <MenuItem data-countryCode="GN" value="224">
              Guinea (+224)
            </MenuItem>

            <MenuItem data-countryCode="GY" value="592">
              Guyana (+592)
            </MenuItem>
            <MenuItem data-countryCode="HT" value="509">
              Haiti (+509)
            </MenuItem>
            <MenuItem data-countryCode="HN" value="504">
              Honduras (+504)
            </MenuItem>

            <MenuItem data-countryCode="HU" value="36">
              Hungary (+36)
            </MenuItem>
            <MenuItem data-countryCode="IS" value="354">
              Iceland (+354)
            </MenuItem>
            <MenuItem data-countryCode="IN" value="91" Selected>
              India (+91)
            </MenuItem>
            <MenuItem data-countryCode="ID" value="62">
              Indonesia (+62)
            </MenuItem>
            <MenuItem data-countryCode="IR" value="98">
              Iran (+98)
            </MenuItem>
            <MenuItem data-countryCode="IQ" value="964">
              Iraq (+964)
            </MenuItem>
            <MenuItem data-countryCode="IE" value="353">
              Ireland (+353)
            </MenuItem>
            <MenuItem data-countryCode="IL" value="972">
              Israel (+972)
            </MenuItem>
            <MenuItem data-countryCode="IT" value="39">
              Italy (+39)
            </MenuItem>

            <MenuItem data-countryCode="JP" value="81">
              Japan (+81)
            </MenuItem>
            <MenuItem data-countryCode="JO" value="962">
              Jordan (+962)
            </MenuItem>
            <MenuItem data-countryCode="KZ" value="7">
              Kazakhstan (+7)
            </MenuItem>
            <MenuItem data-countryCode="KE" value="254">
              Kenya (+254)
            </MenuItem>
            <MenuItem data-countryCode="KI" value="686">
              Kiribati (+686)
            </MenuItem>

            <MenuItem data-countryCode="KW" value="965">
              Kuwait (+965)
            </MenuItem>

            <MenuItem data-countryCode="LA" value="856">
              Laos (+856)
            </MenuItem>
            <MenuItem data-countryCode="LV" value="371">
              Latvia (+371)
            </MenuItem>
            <MenuItem data-countryCode="LB" value="961">
              Lebanon (+961)
            </MenuItem>
            <MenuItem data-countryCode="LS" value="266">
              Lesotho (+266)
            </MenuItem>
            <MenuItem data-countryCode="LR" value="231">
              Liberia (+231)
            </MenuItem>
            <MenuItem data-countryCode="LY" value="218">
              Libya (+218)
            </MenuItem>

            <MenuItem data-countryCode="MO" value="853">
              Macao (+853)
            </MenuItem>
            <MenuItem data-countryCode="MK" value="389">
              Macedonia (+389)
            </MenuItem>
            <MenuItem data-countryCode="MG" value="261">
              Madagascar (+261)
            </MenuItem>
            <MenuItem data-countryCode="MW" value="265">
              Malawi (+265)
            </MenuItem>
            <MenuItem data-countryCode="MY" value="60">
              Malaysia (+60)
            </MenuItem>
            <MenuItem data-countryCode="MV" value="960">
              Maldives (+960)
            </MenuItem>
            <MenuItem data-countryCode="ML" value="223">
              Mali (+223)
            </MenuItem>
            <MenuItem data-countryCode="MT" value="356">
              Malta (+356)
            </MenuItem>

            <MenuItem data-countryCode="YT" value="269">
              Mayotte (+269)
            </MenuItem>
            <MenuItem data-countryCode="MX" value="52">
              Mexico (+52)
            </MenuItem>

            <MenuItem data-countryCode="MD" value="373">
              Moldova (+373)
            </MenuItem>
            <MenuItem data-countryCode="MC" value="377">
              Monaco (+377)
            </MenuItem>
            <MenuItem data-countryCode="MN" value="976">
              Mongolia (+976)
            </MenuItem>

            <MenuItem data-countryCode="MA" value="212">
              Morocco (+212)
            </MenuItem>

            <MenuItem data-countryCode="MN" value="95">
              Myanmar (+95)
            </MenuItem>
            <MenuItem data-countryCode="NA" value="264">
              Namibia (+264)
            </MenuItem>
            <MenuItem data-countryCode="NR" value="674">
              Nauru (+674)
            </MenuItem>
            <MenuItem data-countryCode="NP" value="977">
              Nepal (+977)
            </MenuItem>

            <MenuItem data-countryCode="NI" value="505">
              Nicaragua (+505)
            </MenuItem>
            <MenuItem data-countryCode="NE" value="227">
              Niger (+227)
            </MenuItem>
            <MenuItem data-countryCode="NG" value="234">
              Nigeria (+234)
            </MenuItem>
            <MenuItem data-countryCode="NU" value="683">
              Niue (+683)
            </MenuItem>

            <MenuItem data-countryCode="NO" value="47">
              Norway (+47)
            </MenuItem>
            <MenuItem data-countryCode="OM" value="968">
              Oman (+968)
            </MenuItem>
            <MenuItem data-countryCode="PW" value="680">
              Palau (+680)
            </MenuItem>
            <MenuItem data-countryCode="PA" value="507">
              Panama (+507)
            </MenuItem>

            <MenuItem data-countryCode="PY" value="595">
              Paraguay (+595)
            </MenuItem>
            <MenuItem data-countryCode="PE" value="51">
              Peru (+51)
            </MenuItem>

            <MenuItem data-countryCode="PL" value="48">
              Poland (+48)
            </MenuItem>
            <MenuItem data-countryCode="PT" value="351">
              Portugal (+351)
            </MenuItem>

            <MenuItem data-countryCode="QA" value="974">
              Qatar (+974)
            </MenuItem>
            <MenuItem data-countryCode="RE" value="262">
              Reunion (+262)
            </MenuItem>
            <MenuItem data-countryCode="RO" value="40">
              Romania (+40)
            </MenuItem>
            <MenuItem data-countryCode="RU" value="7">
              Russia (+7)
            </MenuItem>
            <MenuItem data-countryCode="RW" value="250">
              Rwanda (+250)
            </MenuItem>

            <MenuItem data-countryCode="SN" value="221">
              Senegal (+221)
            </MenuItem>
            <MenuItem data-countryCode="CS" value="381">
              Serbia (+381)
            </MenuItem>

            <MenuItem data-countryCode="SI" value="386">
              Slovenia (+386)
            </MenuItem>

            <MenuItem data-countryCode="SO" value="252">
              Somalia (+252)
            </MenuItem>

            <MenuItem data-countryCode="ES" value="34">
              Spain (+34)
            </MenuItem>
            <MenuItem data-countryCode="LK" value="94">
              Sri Lanka (+94)
            </MenuItem>

            <MenuItem data-countryCode="SD" value="249">
              Sudan (+249)
            </MenuItem>
            <MenuItem data-countryCode="SR" value="597">
              Suriname (+597)
            </MenuItem>
            <MenuItem data-countryCode="SZ" value="268">
              Swaziland (+268)
            </MenuItem>
            <MenuItem data-countryCode="SE" value="46">
              Sweden (+46)
            </MenuItem>

            <MenuItem data-countryCode="SI" value="963">
              Syria (+963)
            </MenuItem>
            <MenuItem data-countryCode="TW" value="886">
              Taiwan (+886)
            </MenuItem>
            <MenuItem data-countryCode="TJ" value="7">
              Tajikstan (+7)
            </MenuItem>
            <MenuItem data-countryCode="TH" value="66">
              Thailand (+66)
            </MenuItem>
            <MenuItem data-countryCode="TG" value="228">
              Togo (+228)
            </MenuItem>
            <MenuItem data-countryCode="TO" value="676">
              Tonga (+676)
            </MenuItem>

            <MenuItem data-countryCode="TN" value="216">
              Tunisia (+216)
            </MenuItem>
            <MenuItem data-countryCode="TR" value="90">
              Turkey (+90)
            </MenuItem>
            <MenuItem data-countryCode="TM" value="7">
              Turkmenistan (+7)
            </MenuItem>

            <MenuItem data-countryCode="TV" value="688">
              Tuvalu (+688)
            </MenuItem>
            <MenuItem data-countryCode="UG" value="256">
              Uganda (+256)
            </MenuItem>
            <MenuItem data-countryCode="UA" value="380">
              Ukraine (+380)
            </MenuItem>

            <MenuItem data-countryCode="UY" value="598">
              Uruguay (+598)
            </MenuItem>
            <MenuItem data-countryCode="UZ" value="7">
              Uzbekistan (+7)
            </MenuItem>
            <MenuItem data-countryCode="VU" value="678">
              Vanuatu (+678)
            </MenuItem>

            <MenuItem data-countryCode="VE" value="58">
              Venezuela (+58)
            </MenuItem>
            <MenuItem data-countryCode="VN" value="84">
              Vietnam (+84)
            </MenuItem>

            <MenuItem data-countryCode="ZM" value="260">
              Zambia (+260)
            </MenuItem>
            <MenuItem data-countryCode="ZW" value="263">
              Zimbabwe (+263)
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default Countrycode;
