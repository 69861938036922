import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CardContent from "@mui/material/CardContent";
import DisplayHtmlString from "./DisplayHtmlString";
import DateTimeYear from "./DateTimeYear";

const useStyles = makeStyles((theme) => ({
  size: {
    margin: theme.spacing("4em", "0em", "4em", "6em"),
  },
  banners: {
    width: "100% !important",
  },
  color: {
    margin: theme.spacing("0em", "0em", "0em", "43em"),
    // padding: "!important"
  },
  button: {
    borderRadius: "2em !important",
  },
  cardSize: {
    padding: "1em !important",
    fontFamily: "Raleway !important",
    marginLeft: "6.2em !important",
    marginBottom: "8em !important",
    backgroundColor: "white !important",
    color: "#333 !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1.5em !important",
      marginBottom: "4em !important",
      backgroundColor: "white !important",
      color: "#333 !important",
    },
  },
  setImage: {
    width: "70%",
    marginLeft: "12em !important",
    marginBottom: "0 !important",

    marginTop: "3em !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2.2em !important",
      width: "80% ",
      marginTop: "2em !important",
    },
  },
  heading: {
    textAlign: "center !important",
    marginTop: "2em !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "1em !important",
      marginBottom: "2em !important",
    },
  },
  adjust: {
    padding: "1em !important",
  },
  time: {
    position: "absolute !important",
    marginLeft: "9em !important",
    [theme.breakpoints.down("md")]: {
      position: "absolute !important",
      marginBottom: "2em !important",
    },
  },
  style: {
    fontFamily: "Ralwey !important",
    color: "#333 !important",
    fontSize: "1.2rem !important",
  },
  container: {
    display: "row !important",
  },
  font: {
    fontFamily: "Ralwey !important",
    color: "blue !important",
  },
}));

const BlogID = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [story, setStory] = useState({});
  const url =
    "https://customer.nanohealth.in/api/v2/posts/corporate_nhassurance_blogs";
  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const stories = data.filter((stories) => stories.id == id);

        setStory(...stories);
      });
  }, [id]);

  return (
    <>
      <div className={classes.container}>
        <div>
          <img
            src={story.dummy2}
            alt="green iguana"
            className={classes.setImage}
          />
        </div>
        <div className={classes.time}>
          <DateTimeYear date={story.updated_at} className={classes.time} />
        </div>
      </div>
      <div>
        <Card sx={{ maxWidth: "100%" }}>
          <h2 className={classes.heading}>{story.post_title}</h2>

          <Card sx={{ maxWidth: "78%" }} className={classes.cardSize}>
            <DisplayHtmlString
              content={story?.post_content}
              className={classes.font}
            />

            <CardContent className={classes.display}></CardContent>
          </Card>
        </Card>
      </div>
    </>
  );
};

export default BlogID;
