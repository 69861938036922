import React from "react";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: " relative",
    paddingTop: "56.25%",
  },
  reactplayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    textAlign: "center !important",
    fontFamily: "Raleway !important",
    color: "#8600C9 !important",
    fontWeight: "600 !important",
    fontSize: "1.7rem !important",
    padding: theme.spacing(3),
    margin: theme.spacing(0),
  },
  back: {
    padding: theme.spacing("2em", "3em", "0em", "6.3em"),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing("0em", "1em", "1em", "1em"),
      textAlign: "center !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing("0em", "3em", "1em", "6em"),
      textAlign: "center !important",
    },
  },
  heading: {
    fontFamily: "Raleway !important",
    textAlign: "left !important",
    fontSize: "1.7rem !important",
    marginTop: "3% !important",
    color: "#8600C9 !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center !important",
      paddingTop: "0 !important",
    },
  },
  subheading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.1rem !important",
    color: "black !important",
  },
  local: {
    fontFamily: "Raleway !important",
    textAlign: "left !important",
    fontSize: "1em !important",
    color: "black !important",
    lineHeight: "1.8em!important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center !important",
    },
  },
}));

const Awards = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.back}>
        <h1 className={classes.content}>Proven Impact, Awards and Coverage</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <p
              className={classes.heading}
              variant="h4"
              gutterBottom
              component="div"
            >
              HULT Prize 2014 - Winners{" "}
            </p>
            <p className={classes.local}>
              {" "}
              The Hult Prize,also known as the Nobel Prize for students,
              identifies one social enterprise every year across all the
              universities to solve the most pressing problems of the world.
              Awarded by the likes of Former US PresidentBill Clintonand Noble
              Prize winnerMuhammad Yunus,NanoHealth made history by becoming the
              first Indian team to win the coveted award out of 11,000
              applicants worldwide.
            </p>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <div className={classes.playerWrapper}>
              <ReactPlayer
                className={classes.reactplayer}
                url="http://www.youtube.com/watch?v=8K1mQs1NYok&t=1s"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Awards;
