import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Labcard from "./Labcard";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Blogsbanner from "./Blogsbanner";
import ScreeningCard from "./ScreeningCard";
import TimeForFullscreen from "./TimeForFullscreen";
import Disease from "./DiseaseManagementcard";
import DisplayHtmlString from "./DisplayHtmlString";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  size: {
    margin: theme.spacing("2em", "0em", "4em", "6em"),
    padding: theme.spacing("0em", "0em", "0em", "0em"),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing("0em", "0em", "0em", "0em"),
      margin: theme.spacing("1em", "0em", "4em", "2.3em"),
      justifyContent: "center !important",
      webkitboxPack: "center",
      maxWidth: "80% !important",
    },
  },
  banners: {
    width: "100% !important",
  },
  color: {
    margin: theme.spacing("0em", "0em", "0em", "30em"),
  },
  button: {
    borderRadius: "4em !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0 !important",
    },
  },
  imagessize: {
    width: "100% !important",
  },
  end: {
    marginLeft: "21em !important",
    paddingTop: "0em !important",
  },
  pend: {
    marginLeft: "24.5em !important",
    marginTop: "4em !important",
    width: "40%",
    lineSpacing: "1em !important",
    lineHeight: "1.5rem !important",
  },
  radius: {
    borderRadius: "3em !important",
  },
  buttonPosition: {
    justifyContent: "end !important",
  },

  content: {
    width: "75% ",
    lineHeight: "1.5em !important",
    fontSize: "1em !important",
    fontWeight: "400 !important",
  },
  pad: {
    padding: theme.spacing("1em", "0em", "2em", "6em !important"),
    background: "#e6e7e8 !important",
    fontFamily: "Raleway !important",
    fontSize: "1em !important",

    lineHeight: "1.1em!important",
  },
  heading: {
    fontSize: "2em !important",
    fontWeight: "501!important",
    fontFamily: "Raleway !important",
    marginLeft: "3em !important",
    width: "70% !important",
    color: "#333 !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em !important",

      marginLeft: "2.5em !important",
    },
  },
  display: {
    display: "flex !important",
    padding: "0 !important",
  },
  fontwidth: {
    width: "90% !important",
    marginTop: "1em !important",
    marginLeft: "1em !important",
    fontSize: "1em !important",
  },
}));

const BlogList = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [stories, setStories] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        "https://customer.nanohealth.in/api/v2/posts/corporate_nhassurance_blogs"
      )
      .then((response) => {
        setLoading(false);
        setStories(response.data);
        setError("");
      })
      .catch((error) => {
        setLoading(false);
        setStories([]);
        setError("Some Thing Went Wrong");
      });
  }, []);
  return (
    <div>
      <Disease />
      <Labcard />
      {/* <ScreeningCard /> */}
      <Blogsbanner />
      {stories &&
        stories.map((story) => {
          return (
            <div>
              <h2 className={classes.heading}>
                {loading ? "Loading" : story.post_title}
                {error ? error : null}
              </h2>
              <Card sx={{ maxWidth: "65%" }} className={classes.size}>
                <CardMedia
                  className={classes.imagessize}
                  component="img"
                  alt="green iguana"
                  image={story.dummy2}
                />

                <CardContent className={classes.display}>
                  <div>
                    <TimeForFullscreen date={story.updated_at} />
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={classes.fontwidth}
                    >
                      {loading ? "Loading" : story.meta_description}
                      {error ? error : null}
                    </Typography>
                    <CardActions className={classes.buttonPosition}>
                      <Button
                        size="small"
                        onClick={() => navigate(`${story.id}`)}
                        color="secondary"
                        variant="contained"
                        className={classes.radius}
                      >
                        Read More
                      </Button>
                    </CardActions>
                  </div>
                </CardContent>
              </Card>
            </div>
          );
        })}
    </div>
  );
};

export default BlogList;
