import React from "react";
import Blogs from "../../../assets/Colour.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  size: {
    width: "100%",
    height: "10em !important",
    [theme.breakpoints.down("md")]: {
      height: "5em !important",
    },
  },
  heading: {
    position: "absolute !important",
    marginLeft: "7vw !important",
    marginTop: "4vw !important",
    color: "white !important",
    fontFamily: "Ralwey !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0.7em !important",
      marginTop: "1em !important",
      fontSize: "1.6em !important",
    },
  },
}));

const Blogsbanner = () => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.heading}>Blogs</h1>
      <img src={Blogs} alt="clients logo" className={classes.size} />
    </>
  );
};

export default Blogsbanner;
