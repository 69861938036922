import React, { useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
// import axios from "axios";
// import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import TimePicker from "@mui/lab/TimePicker";
// import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Countrycode from "./Countrycode";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupSchema = yup.object().shape({
  name: yup.string().required(),
  lastname: yup.string().required(),

  // subject: yup.string().required(),
  email: yup.string().email("Invalid email format").required(),
  subject: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .notRequired(),
});

const useStyles = makeStyles((theme) => ({
  btns: {
    backgroundColor: "orange !important",
    alignItems: "center !important",
  },

  heading: {
    fontFamily: "Raleway !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    fontSize: "1.7rem !important",
    // marginTop: '3% !important',
    color: "#8600C9 !important",

    padding: theme.spacing(1),
    marginBottom: "0 !important",
  },
  subheading: {
    textAlign: "center !important",
    fontFamily: "Raleway !important",
    fontSize: "1em !important",
    padding: theme.spacing("0.2em"),
    margin: theme.spacing(0),

    // fontSize:'1rem !important',
  },
  back: {
    padding: theme.spacing("2em", "0em", "0em", "6.5em"),
    backgroundColor: "#FAE8FF !important",
    marginBottom: "3em !important",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing("2em", "4em", "1em", "6.5em"),
    },
  },
  col: {
    color: "red !important",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const onSubmit = (newdata) => {
    fetch(
      "https://customer.nanohealthplan.com/api/v2/enquiry_details/assurance_mail",

      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        reset();
        alert(data.message);
      })
      .catch((error) => {});
  };

  return (
    <div className={classes.back}>
      <Typography
        className={classes.heading}
        variant="h2"
        gutterBottom
        component="div"
      >
        We would like to take care of your health & wellness
      </Typography>
      <Typography
        className={classes.subheading}
        variant="h6"
        gutterBottom
        component="div"
      >
        Submit your request, and our team will connect with you real quick!
      </Typography>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2} padding={"2em"}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="name"
                name="name"
                label="FirstName"
                type="text"
                variant="outlined"
                fullWidth
                autoFocus
                {...register("name", {
                  required: "FirstName is required.",
                })}
              />
              {errors.name && (
                <p className={classes.col}>{errors.name.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="lastname"
                label="LastName"
                variant="outlined"
                type="text"
                fullWidth
                name="lastname"
                {...register("lastname", {
                  required: "LastName is required.",
                })}
              />
              {errors.lastname && (
                <p className={classes.col}>{errors.lastname.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                type="email"
                variant="outlined"
                autoFocus
                fullWidth
                {...register("email", {
                  required: "E-mail Address is required.",
                })}
              />
              {errors.email && (
                <p className={classes.col}>{errors.email.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Countrycode />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="subject"
                name="subject"
                label="Phone no"
                type="number"
                variant="outlined"
                fullWidth
                autoFocus
                {...register("subject", {
                  required: "Contact no is required.",
                })}
              />
              {errors.subject && (
                <p className={classes.col}>{errors.subject.message}</p>
              )}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <TimePicker
                    id="Schedule Time"
                    label="time"
                    type="Time"
                    name="Schedule Time"
                    onChange={handleChange}
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    {...register("Schedule Time", {
                      required: "Schedule Time is required.",
                    })}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid> */}

            <div className="clearfix"></div>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.btns}
              >
                Request Demo
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Contact;
